.TitleBar_old {
    min-height: 60px;
    background-color: #01abb5;
    margin-bottom: 25px;
    color: white;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    flex-direction: row-reverse;
    
    
}

.TitleBar_old .p-button {
    right: 10px;
    position: absolute;
    

}

.TitleBar {
    font-weight: bold;
    color: #6D7173 !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #F9F9F9;
    position: fixed;
    width: 100%;
    z-index: 99;
    top: 0;
}

.TitleBar .menu {
    border: none !important;
    background-color: transparent !important;
    
}

.TitleBar .p-menu .p-submenu-header {

    background-color: #6D7173 !important;
}

.TitleBar .p-menuitem {
    text-align: left;
}