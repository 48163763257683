.Leaderboard {
    font-weight: 200;
}

.Leaderboard .entry {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
    border-bottom: #eee thin solid;
    color: white;
}

.Leaderboard .entry .cell {
    z-index: 1;
}
.Leaderboard .entry .cell.avatar {
    width: 12vw;
    color: black;
}
.Leaderboard .entry .cell.name {
    
}
.Leaderboard .entry .cell.points {
    width: 25vw;
    font-size: 1.5em;
}

.Leaderboard .avatar {
    width: 50px;
    margin-right: 10px;
}

.Leaderboard .avatar img {
    width: 40px;
    height: 40px;
    clip-path: circle(20px at center);
    object-fit: cover;
}

.progressbar {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 15vw;
    border-radius: 0 30px 30px 0;
    background-color: #C0D95E;
    
}

.empty-progressbar {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 15vw;
    border-radius: 0 30px 30px 0;
    background-color: #dfdfdf;
    
}
