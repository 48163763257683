.AchievementDialog .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.AchievementDialog input {
    width: 90%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
}

