nav {
    width: 100%;
    position: fixed;
    bottom: 0;
    /* border-top: thin solid; */
    background-color: #FDFEFF;
    color: #6D6E71;
    height: 50px;
    font-size: 0.8em;
    z-index: 12;
    padding-bottom: 60px;
    padding-top: 10px;
}

nav ul {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
   padding: 0;
   list-style: none;
   
}



nav ul li a{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #6D6E71;
   
}

nav ul li a.active, nav ul li a:hover{
    color: #5DC2A7;
}

nav ul li i {
   font-size: 0.6em;
}
