.Leaderboards {
    margin-top: 53px;
    margin-bottom: 50px;
    min-height: 90vh;
    /* background: linear-gradient(#e9a3f5, #8161da); */
    background-color: white;
}

.leaderboard-text {
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: 200;
    /* color: white; */
}

.noLeaderboards {
    color: white;
}

.board {
    width: 90vw;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    background: white;
    box-shadow: 0px -10px 40px #fff;
}

.tab {
    overflow: hidden;
    /* background-color: rgb(1, 171, 181); */
    background-color: white;
    display: flex;
    justify-content: center;
    position: sticky;
    top: 53px;
    z-index: 3;
}

.tab2 {
    top: 93px;
    justify-content: space-around;
    /* background-color: rgb(68, 33, 97); */
    background-color: white;
}

.tab button {
    background-color: inherit;
    color: #6D7173;
    font-weight: bold;
    border: none;
    outline: none;
    cursor: pointer;
    width: 30vw;
    height: 30px;
    margin: 5px;
    transition: 0.3s;
    border-radius: 30px;
}

.tab button:hover {
    /* background-color: rgb(237, 19, 120); */
    color: rgb(1, 171, 181);
}

.tab button.active {
    /* background-color: rgb(237, 19, 120); */
    color: white;
    background-color: rgb(1, 171, 181);
}

.tab2 button:hover {
    color: rgb(231, 108, 37);
}

.tab2 button.active {
    background-color: rgb(231, 108, 37);
    color: white;
}
