.Profile {
    display: flex;

    color: #808080;
    padding: 10px 0;
    flex-direction: column;
    align-items: center;
    margin-top: 52px;

}

.Profile form {
    width: 98%;
}

.Profile .fullname {
    font-size: 1.4em;
    font-weight: bold;
    margin-bottom: 20px;
}


.Profile .avatar-thumb i {

    width: 120px;
    height: 120px;
    clip-path: circle(60px at center);
    font-size: 4.4em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
}

.Profile .avatar-thumb {
    width: 120px;
    
   
}

.Profile .avatar-thumb img {

    width: 120px;
    height: 120px;
    clip-path: circle(60px at center);
    object-fit: cover;
}

