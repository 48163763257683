.Home {
    margin-bottom: 80px;
    margin-top: 52px;
}

.Home .top-panel {
    min-height: 100px;
    width: 100%;
    /* padding-top: 10px; */
    border-bottom: thin solid #dddddd;
    /* border-top: thin solid #dddddd; */
}

.Home .top-panel .top-panel-left {
   border-right: thin solid #dddddd;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding-top: 20px;
}

.Home .top-panel .top-panel-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    
}

.Home .top-panel .top-panel-left h1, 
.Home .top-panel .top-panel-right h1 {
    background: #FE5A99;
  border-radius: 2em;
  -moz-border-radius: 2em;
  -webkit-border-radius: 2em;
  color: #ffffff;
  display: inline-block;
  /* font-weight: bold; */
  line-height: 1.2em;
  margin-right: 5px;
  text-align: center;
  min-width: 2em;
  padding: 0.4em; 
}

.Home .top-panel .top-panel-right h1 {
    background: #FC844C;
}

.Home .main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.Home .topPeople {
    width: 100%;
    margin-top: 50px;
}
.Home .topPeople ul {
    list-style: none;
    
    
    padding: 0;
}

.Home .topPeople li {
    border-bottom: lightgrey thin solid;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Home .avatar-thumb {
    width: 50px;
    margin-right: 10px;
    
   
}

.Home .avatar-thumb img {

    width: 50px;
    height: 50px;
    clip-path: circle(25px at center);
    object-fit: cover;
}

.Home .points {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50px;
    font-size: 2em;
}