li.Activity {
    display: flex;
    text-align: left;
    font-size: 0.8em;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 10px;
    border-top: 1px solid #F4F5F7;
    min-height: 80px;
    
}

.Activity button.status {
    background-color: white;
    border: 3px solid #97D8C7;
    color: #97D8C7;
    border-radius: 5px;
    height: 23px;
    width: 23px;
    display: flex;
    justify-content: center;
    font-size: 0.8em;
    margin-top: 20px;

}

.Activity button.promptLink {
    color: #97D8C7;
    font-weight: bold;
    padding: 0;
    border: none !important;
    background-color: transparent !important;
    text-align: left;
}



.Activity .text {
    display: flex;
    flex-direction: column;
    font-size: 1.2em;

}

.Activity .text .action {
    
    font-weight: bold;
    font-style: italic;
}

.Activity .text .action i {
    font-size: 1.2em;
    
}

.Activity .text .action a {
    font-size: 1.2em;
    text-decoration: none;
    
    
}

.Activity .points {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    font-size: 2em;
}

.Activity .challengeCompleteText {
    color: rgb(255, 220, 155);
    font-weight: bold;
    padding: 0;
    border: none !important;
    background-color: transparent !important;
    text-align: left;
}

.Activity.challenge {
    background-color: rgb(255, 239, 211);
}

.Activity.complete {
    background-color: rgb(249, 247, 242);
    
}

.Activity .activityPoints {
    padding: 5px;
    font-weight: bold;
    font-style: italic;
}

.Activity .points {
    cursor: pointer;
}

.Activity .help {
    color: #dfdfdf;
    cursor: pointer;
}

.Activity.daily {
    /* background-color: lightseagreen; */
}