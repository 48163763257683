.CategoryHeader {
    color: white;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 18px;
    height: 60px;
    font-weight: bold;
    justify-content: space-between;
    
}

.CategoryHeader .catImage {
    max-height: 40px;
    /* clip-path: circle(37.8% at 50% 50%); */
    z-index: 0;
    
}