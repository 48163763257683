.ChangePassword.hide {
    transform: translateY(100vh);
    transition: transform 0.5s;
}

.ChangePassword.show {
    transform: translateY(none);
    transition: transform 0.5s;
}

.ChangePassword {
    height: 100vh;
    width: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    overflow: scroll;
    z-index: 999;

}

.ChangePassword input {
    width: 90%;
    height: 50px;
    border: none;
    border-bottom: 1px solid #dddddd;
    border-radius: 0;
}

.ChangePassword .title {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: right;
    align-items: right;
    position: fixed;
    
}

.ChangePassword .passwordWrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.ChangePassword .passwordWrapper p.recoverText {
    font-size: 1.5em;
    font-weight: 100;
}

.ChangePassword .passwordWrapper .buttons {
    margin-top: 10px;
}