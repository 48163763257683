
.Activities ul {
    margin-top: 50px;
}
.Activities .p-dialog .p-dialog-content {
    padding-bottom: 40px !important;
}

.Activities .dateSelect-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    position: fixed;
    width: 100%;
    background-color: white;
    top: 0;
    z-index: 5;
    
}

.Activities .dateSelect-wrapper .p-button,
.Activities .p-button:enabled:active, 
.Activities .p-button:enabled:hover {
    background: none;
    color: black;
    border: none;
    
}

.Activities .dateSelect-wrapper .p-button {
    font-size: 1.4em;
}

.Activities .isToday {
    text-transform: uppercase;
}
